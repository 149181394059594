<template>
  <v-main class="login">
    <div v-if="user">
      <div class="px-3 mt-10">
        <v-row class="mb-3">
          <v-col cols="12" class="pb-0">
            <p class="subtitle-2 subtitle-scoped ma-0">登録メールアドレス</p>
            <div>{{ user.mail }}</div>
          </v-col>

          <v-col cols="12" class="pb-0">
            <p class="subtitle-2 subtitle-scoped ma-0">氏名</p>
            <div>{{ user.name }}</div>
          </v-col>

          <v-col cols="12" class="pb-0">
            <p class="subtitle-2 subtitle-scoped ma-0">ニックネーム</p>
            <div>{{ user.nickname }}</div>
          </v-col>

<!--          <v-col cols="12" class="pb-0">-->
<!--            <p class="subtitle-2 subtitle-scoped ma-0">郵便番号</p>-->
<!--            <div>{{ user.zip }}</div>-->
<!--          </v-col>-->

          <v-col cols="12" class="pb-0">
            <p class="subtitle-2 subtitle-scoped ma-0">都道府県</p>
            <div>{{ user.prefecture_name }}</div>
          </v-col>

          <v-col cols="12" class="pb-0">
            <p class="subtitle-2 subtitle-scoped ma-0">生年月日</p>
            <div>{{ user.birthday }}</div>
            <p class="subtitle-2 subtitle-scoped">
              ※生年月日は1度登録した後は変更できません。
            </p>
          </v-col>

          <v-col cols="12" class="pb-0">
            <p class="subtitle-2 subtitle-scoped ma-0">性別</p>
            <div>{{ user.gender_name }}</div>
          </v-col>
        </v-row>

        <div class="text-center">
          <v-btn class="maincolor-bg default_button" @click.stop="clickEditAccount()">
            編集
          </v-btn>
          <p class="pt-2 subtitle-2 subtitle-scoped">
            メルマガの設定変更も上記から行えます。
          </p>
        </div>

        <div class="text-center caption pt-10">
          <p class="subtitle-2 subtitle-scoped">
            パスワードの変更は<a @click="clickChangePassword()">こちら</a>
          </p>
        </div>

        <v-divider class="my-5"></v-divider>

        <div class="text-center caption pt-10">
          <p class="">
            本アプリをご利用の際には、
            <router-link to="/info/rule">利用規約</router-link>
            をご確認ください。
          </p>
          <p class="caption" v-if="init.inquiry_mail">
            <v-icon>far fa-envelope</v-icon>
            <a :href="'mailto:'+init.inquiry_mail">お問い合わせ</a>
          </p>
        </div>
      </div>

      <v-divider class="my-5"></v-divider>

      <v-col cols="12" class="text-right subtitle-2 subtitle-scoped">
        <a @click="clickQuit()">退会する</a>
      </v-col>
    </div>

    <v-overlay :value="loading">
      <v-progress-circular :size="80" :width="2" class="maincolor" indeterminate></v-progress-circular>
    </v-overlay>

  </v-main>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      user: null,
      init: this.storageGet('*'),
      loading: false,
      show: false,
      mailTo: null,

      shopCategories: null,
      shops: null,
      selectedShopCategory: null,
      selectedShop: null,

      NEXT_PAGE_SUB_MENU: '{"_vue_param": true, "transition_type": 3, "feature_id":"sub_menu", "screen_id":"sub_menu", "destination":"sub_menu"}'
    };
  },
  async created () {
    try {
      // アクセストークンチェックAPI
      const token_check_req = {};
      await this.apiCallCustomerPF('/access_token/check/' + this.init.device_uid, token_check_req);

      // ユーザ取得
      const user_req = {
        'yamaya_id': this.init.device_uid,
        'mail': this.init.mail,
      };
      const user_res = await this.apiCallCustomerPF('/user/get', user_req);
      this.$set(this, 'user', user_res.user);
    } catch(e) {
      console.log(e);
      this.user = null;
      await this.requestToNativeToShowLogin();
    }
  },
  mounted () {
  },
  methods: {
    clickEditAccount() {
      const callback = encodeURIComponent(
          'alert:' + this.getUpdateUserAlertJson('@nickname', this.init.device_uid)
          + '|' + this.NEXT_PAGE_SUB_MENU
      )
      this.showEcPage('/omni_member_edit.html', 'yamaya_id=' + this.init.device_uid + '&callback=' + callback)
    },
    clickChangeMail(){
      const callback = encodeURIComponent(
          'alert:' + this.NEXT_PAGE_SUB_MENU
      )
      this.showEcPage('/member_mail_address.html', 'yamaya_id=' + this.init.device_uid + '&callback=' + callback)
    },
    clickChangePassword(){
      const callback = encodeURIComponent(
          'alert:' + this.NEXT_PAGE_SUB_MENU
      )
      this.showEcPage('/member_password.html', 'yamaya_id=' + this.init.device_uid + '&callback=' + callback)
    },
    async clickQuit() {
      const to_native_req = await this.getRequestToNativeToShowTopPageAfterDeleteAccount('ゲスト', 'cleared')
      console.log('to_native_req')
      console.log(to_native_req)

      const callback = encodeURIComponent(
          'alert:' + this.getUpdateUserAlertJson('ゲスト', 'cleared')
          + '|' + JSON.stringify(to_native_req)
      )
      this.showEcPage('/omni_member_edit.html', 'yamaya_id=' + this.init.device_uid  + '&mode=withdrawal' + '&callback=' + callback)
    },
    getUpdateUserAlertJson(user_name, device_uid) {
      return '{"_vue_param": true, "_action_": "update_user", "user_name": "' + user_name + '", "device_uid": "' + device_uid + '"}'
    },
  },
  computed: {
  },
};
</script>

<style scoped>
.fontsize12>>>label {
  font-size: 12px;
}
.subtitle-scoped {
  font-size: 0.75rem !important;
  font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", "YuGothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", "verdana", sans-serif;
  color: #AAAAAA;
}
</style>